<template>
  <div>
    <div v-for="[logisKey, logis] of Object.entries(logisGrouped)">
      <b-form @submit.prevent>
        <b-card>
          <b-card-header v-b-toggle="'collapse' + logisKey">
            <h3>{{ logisKey }} ({{ logis.length }} boxes)</h3>
          </b-card-header>
          <b-card-body>
            <b-collapse :id="'collapse' + logisKey" visible>
              <b-row
                v-for="logi of logis"
                class="mb-2"
                :class="{ 'bg-success': logi.savemsg === 'o' }"
              >
                <b-col cols="3">
                  <b-btn size="lg" @click="postFound(logi)" variant="success"
                    >👌</b-btn
                  >
                  <b-btn size="lg" @click="editMsg(logi)" variant="warning"
                    >✏️</b-btn
                  >
                </b-col>
                <b-col cols="8">
                  <span class="mx-1">
                    <b-input
                      v-focus="logi.focus"
                      @focus="logi.focus = true"
                      @blur="logi.focus = false"
                      @change="postMsg(logi)"
                      v-show="logi.savemsg && logi.savemsg !== 'o'"
                      v-model="logi.savemsg"
                    ></b-input>
                    {{ logi.member.name }}
                    {{ logi.name2 }}
                  </span>
                  <div v-for="order of logi.orders">
                    <span class="mx-1">
                      <b>{{ order.rack }}</b>
                    </span>
                    <span
                      class="mx-1"
                      v-html="tnumLastDigits(order.tnum)"
                    ></span>
                    <b-badge variant="info">{{ order.shopName }}</b-badge>
                  </div>
                </b-col>
                <b-col cols="1">
                  <a
                    class="btn"
                    target="_blank"
                    :href="
                      'https://www.uniauc.com/admin/logis_list.php?p=0326&searchKey=idx&searchVal=' +
                      logi.idx
                    "
                    >🔗</a
                  >
                </b-col>
              </b-row>
            </b-collapse>
          </b-card-body>
        </b-card>
      </b-form>
    </div>
    <b-modal id="saveMsgModal" hide-footer hide-header>
      <b-form @submit.prevent="onEdit">
        <b-input ref="saveMsgInput" v-model="saveMsg"></b-input>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { focus } from "vue-focus";

const synth = window.speechSynthesis;
const speak = (word) => {
  const utterThis = new SpeechSynthesisUtterance(word);
  utterThis.lang = "en-US";
  synth.speak(utterThis);
};

export default {
  directives: { focus },
  components: {},
  data() {
    return {
      saveMsgIdx: 0,
      saveMsg: "",
      logisGrouped: [],
      ws: {},
      glocingWs: {},
      loading: false,
      input: "",
      rackEntered: true,
      currentBoxes: [],
      userid: "",
      type: "",
      errorInfo: "",
      boxes: [],
      midx: "",
      tnum: "",
      history: [],
      stock: [],
    };
  },
  methods: {
    async onEdit() {
      await this.postMsg(this.saveMsg, this.saveMsgIdx);
    },
    editMsg(logi) {
      logi.savemsg = " ";
      logi.focus = true;
      // this.saveMsgIdx = logi.idx;
      // this.$bvModal.show('saveMsgModal');
    },
    async postFound(logi) {
      logi.savemsg = "o";
      await this.postMsg(logi);
    },

    async postMsg(logi) {
      await this.$post("/save-msg", { savemsg: logi.savemsg, idx: logi.idx });
    },
    tnumLastDigits(tnum) {
      let last4 = tnum.substr(tnum.length - 4);
      const last4half1 = last4.substr(0, 2);
      const last4half2 = last4.substr(2);

      if (last4half2 === "01") {
        return `<b class="text-danger">${last4half1}</b>${last4half2}`;
      } else {
        return `${last4half1}<b class="text-danger">${last4half2}</b>`;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // this.ws.close();
    next();
  },
  watch: {
    history(history) {
      localStorage.setItem("history", JSON.stringify(history));
    },
  },
  async created() {
    this.loading = true;
    this.logisGrouped = await this.$get(
      "/to-pack/" + this.$route.params.region
    );
    console.log(this.items);
    this.loading = false;
  },
  mounted() {},
};
</script>
<style scoped>
.b-card {
  font-family: Courier serif;
}
</style>
