<template>
  <div id="app">
    <Navbar></Navbar>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view ref="app" />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
    <notifications position="bottom center"> </notifications>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import Navbar from "./Navbar";
import commonMessages from "../public/locales.json"; // import common locale messages

export default {
  i18n: {
    messages: {},
    sharedMessages: commonMessages,
  },
  components: {
    FadeTransition,
    Navbar,
  },
  data: () => ({
    showSnackbar: false,
    position: "center",
    duration: 4000,
    isInfinity: false,
  }),
  mounted() {
    window.$vm = this.$refs.app;
  },
};
</script>
<style></style>
