<template>
  <div>
    <b-navbar toggleable="sm" type="dark" variant="primary">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand class="d-none d-sm-block" to="/">Admin </b-navbar-brand>
      <div v-if="shops.length > 1" class="mx-3">
        <b-select
          size="sm"
          v-model="shop.nick"
          :options="shops"
          @change="changeAccount"
        ></b-select>
      </div>
      <b-navbar-brand v-if="$store.state.user.isAdmin" to="/sell"
        >{{ $t("Sell") }}
      </b-navbar-brand>
      <b-navbar-brand to="/stocking-union/US/njx">{{
        $t("Stocking(US)")
      }}</b-navbar-brand>
      <b-navbar-brand to="/stocking-union/CN/njx">{{
        $t("Stocking(CN)")
      }}</b-navbar-brand>
      <b-navbar-brand to="/order-list/logis">{{ $t("Orders") }}</b-navbar-brand>
      <b-navbar-brand to="/to-pick/US"
        ><i class="fas fa-exchange-alt"></i>
        {{ $t("Picking") }}
      </b-navbar-brand>
      <b-navbar-brand to="/to-pack/US"
        ><i class="fas fa-exchange-alt"></i>
        {{ $t("Packing") }}
      </b-navbar-brand>
      <b-navbar-brand to="/save-documents"
        ><i class="fas fa-exchange-alt"></i>
        {{ $t("Packing") }}
      </b-navbar-brand>
      <b-collapse is-nav id="nav_collapse">
        <!--        <b-navbar-nav>-->
        <!--        </b-navbar-nav>-->

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!--            <template v-slot:button-content>-->
            <!--              <img-->
            <!--                v-if="$i18n.locale === 'en-US'"-->
            <!--                src="../assets/flag_en-US.png"-->
            <!--              />-->
            <!--              <img-->
            <!--                v-if="$i18n.locale === 'zh-CN'"-->
            <!--                src="../assets/flag_cn.png"-->
            <!--              />-->
            <!--              <img v-if="$i18n.locale === 'ko'" src="../assets/flag_kr.png" />-->
            <!--            </template>-->
            <!--            <b-dropdown-item @click="setLocale('en-US')">-->
            <!--              <img src="../assets/flag_en-US.png" /> English-->
            <!--            </b-dropdown-item>-->
            <!--            <b-dropdown-item @click="setLocale('zh-CN')">-->
            <!--              <img src="../assets/flag_cn.png" /> 简体中文-->
            <!--            </b-dropdown-item>-->
            <!--            <b-dropdown-item @click="setLocale('ko')">-->
            <!--              <img src="../assets/flag_kr.png" /> 한국어-->
            <!--            </b-dropdown-item>-->
          </b-nav-item-dropdown>

          <template v-if="$store.state.user.nick">
            <b-nav-item-dropdown right>
              <template v-slot:button-content>
                <i class="fas fa-book"></i>
                {{ $store.state.user.nick }}
              </template>
              <!-- Using button-content slot -->
              <!--<b-dropdown-item href="#">Setting</b-dropdown-item>-->
              <!--              <b-dropdown-item to="/change_password">{{ $t('Change Password') }}</b-dropdown-item>-->
              <b-dropdown-item @click="logout"
                >{{ $t("Log out") }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </template>
          <template v-else>
            <!--            <b-nav-item @click="authorize('')">{{ $t("Login") }}</b-nav-item>-->
            <b-nav-item to="/login">{{ $t("Log in") }}</b-nav-item>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shop: "",
      shops: [],
    };
  },
  methods: {
    async changeAccount() {
      console.log(11222);
      const { data: authorized } = await this.$api.post("/switch-shop", {
        nick: this.shop.nick,
      });
      console.log(authorized);
      if (authorized.err) {
        this.$warn(authorized.err);
        return false;
        // return falauthorize/tbse;
      }
      await this.$store.dispatch("getSession");
    },
    async authorize(page = "") {
      //
      // location.href = `https://oauth.taobao.com/authorize?response_type=code&client_id=12674713&redirect_uri=https://yun.xfmmc.cn/tbauth.aspx&state=${
      //   location.host === "kr.resell.glocing.com"
      //     ? "http://kr.api.resell.glocing.com"
      //     : location.host === "resell.glocing.com"
      //       ? "https://api.resell.glocing.com"
      //       : process.env.NODE_ENV === "development"
      //         ? "http://localhost:4005"
      //         : "https://api.resell.glocing.com"
      // }${page}/authorize/tb`;
      // await this.$api.get("/authorize");
      // let r = await this.$store.dispatch("getSession");
    },

    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$warn(this.$t("Successfully logged out"), "success");
        this.$router.push("/");
      } catch (e) {
        console.log(e);
        this.$warn(this.$t("Error"));
      }
    },

    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
  },
  async mounted() {
    console.log(this.$i18n.locale);
  },
  async created() {},
};
</script>
