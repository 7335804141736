import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
Vue.use(Vuex);
import axios from "axios";

console.log(process.env.NODE_ENV);
let baseURL;
if (location.hostname === "resell.glocing.com") {
  baseURL = "https://api.resell.glocing.com";
} else if (
  location.hostname.includes("192.168.") ||
  location.hostname === "localhost"
) {
  baseURL = "http://" + location.hostname + ":4005";
} else if (location.hostname === "kr.resell.glocing.com") {
  baseURL = "http://kr.api.resell.glocing.com";
} else if (location.hostname === "glocing.com") {
  // baseURL = "http://localhost:4005";
  baseURL = "http://glocing.com:4005";
}
axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
const store = new Vuex.Store({
  getters: {
    isAuthenticated: (state) => !!state.user.shopId,
    stateUser: (state) => state.user,
  },
  state: {
    user: { isAuthenticated: null },
    copiedCategory: {},
    modalCategory: {},
    lang: "en",
    currency: "USD",
    balance: 0,
  },
  mutations: {
    setCopiedCategory(state, category) {
      state.copiedCategory = category;
    },
    setModalCategory(state, category) {
      state.modalCategory = category;
    },
    setBalance(state, balance) {
      state.balance = balance;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
    setCart(state, { cartItem, type }) {
      // state.cart[type] = {...state.cart[type], ...cartItem}
    },
    SET_USER(state, data) {
      state.user = { ...state.user, ...data };
      if (state.user.resellerId) {
        state.user.isAuthenticated = true;
      } else {
        state.user.isAuthenticated = false;
      }
    },
    DELETE_USER(state) {
      state.user = {
        isAuthenticated: false,
      };
    },
    warn(state, data) {
      let msg, sec;
      if (typeof data === "object") {
        ({ msg, sec = 5 } = data);
      } else {
        msg = data;
        sec = 5;
      }
      state.alert_msg = msg;
      state.dismissCountDown = sec;
    },
    countDown(state, dismissCountDown) {
      state.dismissCountDown = dismissCountDown;
    },
  },
  actions: {
    // nuxtServerInit is called by Nuxt.js before server-rendering every page
    async getSession({ commit }) {
      const { data } = await axios.get("/get-session");
      const { id, marketId, shopId, nick, email, level, isAdmin, resellerId } =
        data;
      commit("SET_USER", {
        id,
        marketId,
        shopId,
        nick,
        email,
        level,
        isAdmin,
        resellerId,
      });

      return data;
    },

    async setShopId({ commit }, shopId) {
      commit("SET_USER", { shopId });
      return { error: "" };
    },
    async login({ commit }, { email, password }) {
      try {
        // const { data } = await axios.post("/authorize/tb", {
        const { data } = await axios.post("/login", {
          email,
          password,
        });

        if (data.error) return { error: data.error };
        const { resellerId, nick, shopId, marketId, level, useOtp } = data;
        commit("SET_USER", {
          resellerId,
          nick,
          email,
          shopId,
          marketId,
          level,
          useOtp,
        });
        console.log({
          resellerId,
          nick,
          email,
          shopId,
          marketId,
          level,
          useOtp,
        });
        return { error: "" };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          return { error: "Bad credentials" };
        } else {
          return { error: "Server error" };
        }
      }
    },
    async logout({ commit }) {
      await axios.post("/logout");
      commit("DELETE_USER");
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;
