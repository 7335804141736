<template>
  <div>
    <div>
      <b-table :items="profitSum.profitSumJig">
        <template #cell(profit)="data">
          <span>{{ data.item.profit.toFixed(2) }}</span>
        </template>
      </b-table>
      <b-table :items="profitSum.profitSumUni">
        <template #cell(profit)="data">
          <span>{{
            new Intl.NumberFormat("ko-KR", {
              style: "currency",
              currency: "USD",
            }).format(data.item.profit)
          }}</span>
        </template>
      </b-table>
    </div>
    <b-form @submit.prevent="submit">
      <b-textarea rows="20" v-model="documents.rates"></b-textarea>
      <b-textarea rows="20" v-model="documents.awbs"></b-textarea>
      <b-btn type="submit">Submit</b-btn>
    </b-form>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      profitSum: [],
      documents: {
        rates: "",
        awbs: "",
      },
    };
  },
  methods: {
    async submit() {
      const data = await this.$post("/save-documents", this.documents);
      this.$warn(data.msg);
      this.init();
    },
    async init() {
      this.loading = true;
      const profitSum = await this.$get("/profit-sum");
      this.profitSum = profitSum;

      this.loading = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    // this.ws.close();
    next();
  },
  watch: {
    history(history) {
      localStorage.setItem("history", JSON.stringify(history));
    },
  },
  async created() {
    this.init();
  },
  mounted() {},
};
</script>
<style scoped>
.b-card {
  font-family: Courier serif;
}
</style>
