<template>
  <b-container fluid>
    <b-card>
      <b-form @submit.prevent="onSearch">
        <b-row>
          <b-col>
            <b-select :options="searchFields" v-model="searchForm.searchField">
            </b-select>
          </b-col>
          <b-col>
            <b-input v-model="searchForm.searchValue" />
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card class="my-1" :key="fee.idx" v-for="(fee, feeIndex) of fees">
      <b-card-header>
        <b-row>
          <b-col>
            <font-awesome-icon icon="edit" @click="bulkEdit(feeIndex)" />
            {{ fee.region }}
            [{{ fee.idx }}]
            <b-badge variant="danger" v-if="fee.userid === 'glomarketb'">
              {{ fee.userid }}</b-badge
            >
            <span v-else>{{ fee.userid }}</span>
            {{ fee.feecode }}
            Created: {{ fee.writeday }}
          </b-col>
          <b-col
            class="text-right"
            v-if="$route.query['status%3Borders'] === '1'"
          >
            <b-btn
              v-if="fee.orders[0].paid === -1"
              :pressed.sync="fee.currentState === 'confirm'"
              variant="success"
              @click="submit('confirm', { feecode: fee.feecode }, fees)"
            >
              Confirm
            </b-btn>
            <b-btn
              type="button"
              :pressed.sync="fee.currentState === 'partialRetry'"
              variant="secondary"
              @click="submit('partialRetry', { feecode: fee.feecode }, fees)"
            >
              Partial Retry
            </b-btn>
            <b-btn
              type="button"
              :pressed.sync="fee.currentState === 'retryAll'"
              variant="info"
              @click="
                submit(
                  'retry',
                  {
                    feecode: fee.feecode,
                    idxs: fee.orders.map((order) => order.idx),
                  },
                  fees
                )
              "
            >
              Retry All
            </b-btn>
            <b-btn
              type="button"
              :pressed.sync="fee.currentState === 'cancelAll'"
              variant="warning"
              @click="
                submit(
                  'cancel',
                  {
                    feecode: fee.feecode,
                    idxs: fee.orders.map((order) => order.idx),
                  },
                  fees
                )
              "
            >
              Cancel All
            </b-btn>
          </b-col>
          <b-col class="text-right" v-if="$route.query.status === '3'">
            <b-btn
              type="button"
              :pressed.sync="fee.currentState === 'shipped'"
              variant="success"
              @click="
                submit(
                  'shipped',
                  {
                    feecode: fee.feecode,
                    idxs: fee.orders.map((order) => order.idx),
                  },
                  fees
                )
              "
            >
              All shipped
            </b-btn>
            <b-btn
              type="button"
              :pressed.sync="fee.currentState === 'canceled'"
              variant="danger"
              @click="
                submit(
                  'canceled',
                  {
                    feecode: fee.feecode,
                    idxs: fee.orders.map((order) => order.idx),
                  },
                  fees
                )
              "
            >
              All canceled
            </b-btn>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row
          :key="order.idx"
          v-for="(order, orderIndex) of fee.orders"
          class="border-bottom mt-1"
        >
          <b-col cols="1">
            <a
              target="_blank"
              :href="
                'https://www.uniauc.com/admin/logis_list.php?receive_nation=&status=&act=search&searchKey=idx%3Borders&searchVal=' +
                order.idx +
                '&searchType=perfect'
              "
            >
              <b-img width="40" height="40" :src="order.img"></b-img>
            </a>
          </b-col>
          <b-col cols="7" :class="'bg-' + stateColors[order.status]">
            <div></div>
            <font-awesome-icon
              icon="edit"
              @click="edit(feeIndex, orderIndex)"
            />
            [{{ order.idx }}]
            <b-badge
              class="mr-1"
              :variant="order.cnt > 1 ? 'warning' : 'secondary'"
              >{{ order.cnt }}</b-badge
            >
            <a target="_blank" :href="order.url">
              <span
                :class="{
                  'text-light': order.status === 2 || order.status === 3,
                }"
                >{{ order.title }}</span
              ></a
            >
            {{ order.category }}<br />
            {{ order.deliverydate_info }} {{ order.msgs }}
            {{ order.status === 1 ? order.error : "" }}
          </b-col>
          <b-col>
            {{ order.price }} ({{ getCurrencyUnit(order.targetRegion)
            }}{{ order.charge }})
            <div v-if="order.cnt > 1">
              {{ order.price * order.cnt }} ({{
                getCurrencyUnit(order.targetRegion)
              }}{{ order.charge * order.cnt }})
            </div>
          </b-col>
          <b-col>
            <b-btn-group v-if="!$route.query.status">
              <b-btn
                :pressed.sync="order.currentState === 'retry'"
                variant="info"
                @click="submit('retry', { idxs: [order.idx] }, order)"
              >
                Retry
              </b-btn>
              <b-btn
                v-if="order.paid !== -1"
                :pressed.sync="order.currentState === 'bought'"
                variant="success"
                @click="submit('bought', { idxs: [order.idx] }, order)"
              >
                Bought
              </b-btn>
              <b-btn
                v-if="order.userid === 'rakuten'"
                :pressed.sync="order.currentState === 'canceled'"
                variant="danger"
                @click="submit('canceled', { idxs: [order.idx] }, order)"
              >
                delete
              </b-btn>
              <b-btn
                v-else
                :pressed.sync="order.currentState === 'cancel'"
                variant="warning"
                @click="submit('cancel', { idxs: [order.idx] }, order)"
              >
                Cancel
              </b-btn>
            </b-btn-group>
            <b-btn-group v-if="$route.query.status === '3'">
              <b-btn
                :pressed.sync="order.currentState === 'retry'"
                variant="info"
                @click="submit('retry', { idxs: [order.idx] }, order)"
              >
                Retry
              </b-btn>
              <b-btn
                :pressed.sync="order.currentState === 'shipped'"
                variant="success"
                @click="submit('shipped', { idxs: [order.idx] }, order)"
              >
                Shipped
              </b-btn>
              <b-btn
                :pressed.sync="order.currentState === 'cancel'"
                variant="warning"
                @click="submit('cancel', { idxs: [order.idx] }, order)"
              >
                Cancel
              </b-btn>
              <b-btn
                :pressed.sync="order.currentState === 'canceled'"
                variant="danger"
                @click="submit('canceled', { idxs: [order.idx] }, order)"
              >
                Canceled
              </b-btn>
            </b-btn-group>
            <!--        Current:-->
            <!--        <b-btn>{{order.currentState}}</b-btn>-->
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-pagination
      align="center"
      class="my-3"
      :limit="10"
      :total-rows="total"
      v-model="currentPage"
      :per-page="10"
    ></b-pagination>

    <b-modal id="editModal" @ok="onEdit" hide-footer hide-header>
      <b-form @submit.prevent="onEdit">
        <b-form-group
          :key="key"
          v-for="{ name, key } of editGroup"
          description=""
        >
          <label :for="key">
            {{ (name ? name : key).toUpperCase() }}
            <b-badge @click="bulkEdit(currentFeeIndex, key)">More</b-badge>
          </label>
          <b-form-input
            @change="pushChange(key, $event)"
            :id="key"
            v-model="fees[currentFeeIndex].orders[currentOrderIndex][key]"
            placeholder=""
          />
        </b-form-group>
        <b-btn type="submit">OK</b-btn>
        <b-btn @click="$bvModal.hide('editModal')">CANCEL</b-btn>
      </b-form>
    </b-modal>
    <b-modal id="bulkEditModal" @ok="onEdit">
      <b-form>
        <b-form-group
          :key="id"
          v-for="(order, id) of fees[currentFeeIndex].orders"
          description=""
        >
          <label :for="order.idx + ''">
            [{{ order.idx }}] {{ currentBulkKey }}
          </label>
          <b-form-input
            :id="order.idx + ''"
            v-model="order[currentBulkKey]"
            placeholder=""
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
const axios = require("axios");

export default {
  name: "smartstore",
  components: {},
  data() {
    return {
      searchForm: {
        searchField: "status;orders",
        searchValue: "1",
        searchType: "",
      },
      searchFields: [],
      stateColors: {
        1: "info",
        2: "secondary",
        3: "secondary",
        4: "success",
      },
      currentBulkKey: "",
      currentOrder: {},
      currentFeeIndex: 0,
      currentOrderIndex: 0,
      currentId: 0,
      form: {
        // url: "",
        // cnt: "",
        price: "",
        // charge: ""
      },
      editGroup: [
        { name: "URL", key: "url" },
        { name: "Title", key: "title" },
        { name: "Qty", key: "cnt" },
        { name: "Price", key: "price" },
        { name: "Charge", key: "charge" },
        { name: "Delivery Info", key: "deliverydate_info" },
        { name: "Arrives At", key: "arrivesAt" },
        { key: "error" },
        { key: "status" },
        { key: "status" },
        { name: "Order No.", key: "trade_id" },
        { name: "Short Tracking", key: "stnum" },
        { name: "Tracking", key: "tnum" },
      ],
      fees: [{ orders: [{}] }],
      page: 1,
      filter: "",
      historyFilter: "",
      currentPage: 1,
      current: [],
      fields: ["img", "title", "price", "cnt", "btn"],
      items: [],
      currentFields: {
        img: {},
        title: {
          sortable: true,
        },
        size: {},
        qty: {
          sortable: true,
        },
      },
      total: 0,
      stockData: "",
    };
  },

  methods: {
    getCurrencyUnit(region) {
      switch (region) {
        case "KR":
          return "KR￦";
        case "JP":
          return "JP¥";
        case "CN":
          return "CN¥";
        case "US":
          return "US$";
      }
    },
    pushChange(key, evt) {
      this.currentOrder.changed[key] = evt;
    },
    async onSearch() {
      const {
        data: { fees, fields },
      } = await axios.post(`/search-orders/fees`, this.searchForm);
      this.fees = fees;
      this.total = fees.length;
      this.searchFields = [...fields.orders, ...fields.fees];
    },
    async onEdit() {
      const post = this.fees[this.currentFeeIndex].orders
        .map((order) => ({
          idx: order.idx,
          ...order.changed,
        }))
        .filter((order) => order !== {});
      if (post === {}) alert("nothing changed");
      else {
        await axios.post("/edit-order", post);
      }
      this.$bvModal.hide("editModal");
    },
    edit(feeIndex, orderIndex) {
      this.currentFeeIndex = feeIndex;
      this.currentOrderIndex = orderIndex;
      this.currentOrder = this.fees[feeIndex].orders[orderIndex];
      this.currentOrder.changed = {};
      this.$bvModal.show("editModal");
    },
    bulkEdit(feeIndex, bulkKey) {
      this.currentFeeIndex = feeIndex;
      this.currentBulkKey = bulkKey;
      this.fees[feeIndex].orders.forEach((order) => (order.changed = {}));
      this.$bvModal.show("bulkEditModal");
    },
    async submit(stateCode, params, orderOrFee) {
      if (
        stateCode === "retry" &&
        this.$route.query.status > 1 &&
        !confirm("sure?")
      ) {
        return false;
      }
      const { idxs, feecode } = params;
      orderOrFee.currentState = stateCode;
      console.log(this.$route.query.status);

      const rtn = await axios.post("/change-status", {
        safeMode:
          !this.$route.query.status || this.$route.query.status === "1" ? 1 : 0,
        stateCode,
        idxs,
        feecode,
      });
      this.$notify(rtn);
    },
    async init() {
      if (this.$route.query) {
        for (const [key, val] of Object.entries(this.$route.query)) {
          this.searchForm.searchValue = val;
          this.searchForm.searchField = key;
        }
      }
      this.onSearch();
    },
    async onSubmit() {
      await this.$api.post("/stock", {
        stockData: this.stockData,
      });
      this.stockData = "";
      this.init();
    },
  },
  watch: {
    currentPage(newPage) {
      console.log(newPage);
      this.$router.push({
        params: { page: newPage },
        query: this.$route.query,
      });
      this.init();
    },
  },
  async created() {
    this.init();
  },
  mounted() {},
};
</script>
