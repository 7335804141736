<template>
  <b-container fluid>
    <b-pagination class="my-3" :limit="20" :total-rows="total" v-model="currentPage" :per-page="40"/>
    <div v-for="product in products" :key="product.uid">
      <b-media class="mt-2">
        <b-img-lazy @click.native="open(product.img)" slot="aside" :src="product.img" height="200"/>
        <b-img-lazy @click.native="open(product.manage.img)" slot="aside" :src="product.manage.img"
                    height="200"/>
        <b-card :bg-variant="product.manage.basePrice ? 'success':''">
          <b-card-header>
              <span
                @click="open(!product.mid ? product.url : 'https://search.shopping.naver.com/detail/lite.nhn?nv_mid='+product.mid)">
                <b-badge>
                  ${{(product.net - product.manage.weight).toFixed(2)}}
                </b-badge>
                <b-badge variant="warning">
                  ${{(product.manage.basePrice-product.manage.cost).toFixed(2)}}
                </b-badge>
                <b-badge>
                  ₩{{product.price.toLocaleString('en-US')}}
                </b-badge>
                <b-badge>
                  ₩{{product.shippingFee.toLocaleString('en-US')}}
                </b-badge>
                {{product.modelName || product.title}}
              </span>


            <b-btn link size="sm" @click="copy(product.id)">Copy</b-btn>
            <b-btn link size="sm" @click="del(product.uid)" variant="danger">Del</b-btn>
          </b-card-header>
          <b-card-body>
            <b-form @submit.prevent="submit(product)" @change="submit(product)">

              <b-input
                @dblclick.native="open('https://smartstore.naver.com/happywellbeing/products/'+product.manage.code1)"
                placeholder="한글품명" v-model="product.manage.titleKr"/>

              <b-input class="manage-data" placeholder="Info URL" v-model="product.manage.url"
                       @dblclick.native="open(product.manage.url)"/>
              <b-input class="manage-data" placeholder="Product cost" @change="costChange(product)"
                       v-model="product.manage.cost"/>
              <b-input class="manage-data" placeholder="Selling price" @change="basePriceChange(product)"
                       v-model="product.manage.basePrice"/>
              <b-input class="manage-data" placeholder="English product title"
                       v-model="product.manage.titleEn"/>
              <b-input class="manage-data" placeholder="weight" @change="weightChange(product)"
                       v-model="product.manage.weight"/>
              <b-input class="manage-data" placeholder="image link" v-model="product.manage.img"/>
              <b-input class="manage-data" placeholder="Stock quantity"
                       v-model="product.manage.availableQty"/>
              <b-input class="manage-data" placeholder="converted price" @change="priceChange(product)"
                       v-model="product.manage.price"/>
              <b-input class="manage-data" placeholder="converted shipping fee" @change="shippingFeeChange(product)"
                       v-model="product.manage.shippingFee"/>
              <b-input class="manage-data" placeholder="shipping qty limit" @change="shippingFeeChange(product)"
                       v-model="product.manage.shippingQtyRepeat"/>

              <b-btn v-show="false" type="submit"/>
            </b-form>
          </b-card-body>
        </b-card>
      </b-media>
    </div>

  </b-container>
</template>

<script>
const axios = require("axios");
// axios.defaults.baseURL = "http://localhost:3005";

export default {
  name: "smartstore",
  components: {},
  data() {
    return {
      total: 1000,
      currentPage: 1,
      products: []
    };
  },
  methods: {
    calcPrice(product) {
      let customFee = 0;
      if (JSON.parse(product.cids)[0] === "50000006") customFee = 1500;

      return (
        Math.round(
          ((product.manage.basePrice * 1 + 4 + product.manage.weight * 1.0) *
            1150 +
            customFee -
            product.manage.shippingFee * 0.9626) /
            0.9426 /
            10
        ) * 10
      );
    },
    calcBasePrice(product) {
      let customFee = 0;
      if (JSON.parse(product.cids)[0] === "50000006") customFee = 1500;
      return (
        (product.manage.price * 0.9426 +
          product.shippingFee * 0.9626 -
          customFee) /
          1150 -
        4 -
        product.manage.weight * 1.0
      );
    },
    costChange(product) {
      product.manage.basePrice = (product.manage.cost * 1.1).toFixed(2);
      this.basePriceChange(product);
    },
    basePriceChange(product) {
      product.manage.price = this.calcPrice(product);
    },
    weightChange(product) {
      this.basePriceChange(product);
    },
    priceChange(product) {
      product.manage.basePrice = this.calcBasePrice(product);
    },
    async copy(id) {
      const result = await axios.post("http://localhost:3005/copy", { id });
      if (!result.error) {
        this.$warn("Copied");
        this.init();
      }
    },
    async del(uid) {
      const result = await axios.post("http://localhost:3005/delete", { uid });
      if (!result.error) {
        this.$warn("deleted");
        this.init();
      }
    },
    async submit(product) {
      const { data } = await axios.post("http://localhost:3005/products", {
        ...product,
        params: this.$route.query
      });
      product.manage = data;
    },
    open(link) {
      window.open(link);
    },
    async init() {
      const { page = 1 } = this.$route.params;
      const {
        data: { results: data }
      } = await axios.get("http://localhost:3005/products/" + page, {
        params: this.$route.query
      });
      this.products = data.map(product => {
        if (!product.img) product.img = "";
        if (!product.manage)
          product.manage = {
            url: "",
            titleKr: product.title,
            img: product.img,
            weight: 0.5,
            availableQty: 1,
            price: product.price - 100,
            shippingFee: product.shippingFee,
            shippingQtyRepeat: 1
          };
        console.log(product);
        if (!product.manage.shippingFee)
          product.manage.shippingFee = product.shippingFee;
        return product;
      });
      console.log(data);
    }
  },
  watch: {
    currentPage(newPage) {
      console.log(newPage);
      this.$router.push({
        params: { page: newPage },
        query: this.$route.query
      });
      this.init();
    }
  },
  async created() {
    await this.init();
  },
  mounted() {}
};
</script>
<style scoped>
input {
  margin-top: 5px;
}

.manage-data {
  width: 19%;
  display: inline;
  margin-right: 1%;
}
</style>
