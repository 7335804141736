import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

import vueInsomnia from "vue-insomnia";
Vue.use(vueInsomnia);

import store from "./store";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// import Argon from "./plugins/argon-kit";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import Notifications from "vue-notification";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import "vue-material/dist/vue-material.min.css";
//
// import { MdButton } from "vue-material/dist/components";
//
// Vue.use(MdButton);

/*
or for SSR:
import Notifications from 'vue-notification/dist/ssr.js'
*/

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faEdit);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
// Vue.use(Argon);

const axiosRO = axios.create();
if (location.hostname === "admin.jiggujiggu.com") {
  global.baseAPIURL = "https://api.jiggujiggu.com";
  global.baseWSURL = "wss://api.jiggujiggu.com";
  global.basePHPURL = "https://www.jiggujiggu.com";
  axios.defaults.baseURL = global.baseAPIURL;
} else if (location.hostname === "admin.uniauc.com") {
  global.baseAPIURL = "https://api.uniauc.com";
  global.baseWSURL = "wss://api.uniauc.com";
  global.basePHPURL = "https://www.uniauc.com";
  axios.defaults.baseURL = global.baseAPIURL;
  axiosRO.defaults.baseURL = "https://api.uniauc.com";
  // axiosRO.defaults.baseURL = 'https://us.api.uniauc.com';
} else if (location.hostname === "us.admin.uniauc.com") {
  global.baseAPIURL = "https://api.uniauc.com";
  global.baseWSURL = "wss://api.uniauc.com";
  global.basePHPURL = "https://www.uniauc.com";
  axios.defaults.baseURL = global.baseAPIURL;
  // axiosRO.defaults.baseURL = "https://api.uniauc.com";
  axiosRO.defaults.baseURL = "https://us.api.uniauc.com";
} else if (["glocing.com"].includes(location.hostname)) {
  global.baseAPIURL = "http://dev.api.uniauc.com";
  global.baseWSURL = "wss://api.uniauc.com";
  global.basePHPURL = "https://www.uniauc.com";
} else if (["www.glocing.com"].includes(location.hostname)) {
  global.baseAPIURL = "https://api.uniauc.com";
  global.baseWSURL = "wss://api.uniauc.com";
  global.basePHPURL = "https://www.uniauc.com";
  axios.defaults.baseURL = global.baseAPIURL;
} else if (["localhost"].includes(location.hostname)) {
  global.baseAPIURL = "http://localhost:3003";
  global.baseWSURL = "wss://api.uniauc.com";
  global.basePHPURL = "https://www.uniauc.com";
  axios.defaults.baseURL = global.baseAPIURL;
  // axiosRO.defaults.baseURL = "https://us.api.uniauc.com";
  axiosRO.defaults.baseURL = "http://localhost:3003";
} else {
  global.baseAPIURL = "https://api.uniauc.com";
  global.baseWSURL = "wss://api.uniauc.com";
  axios.defaults.baseURL = global.baseAPIURL;
}

axios.defaults.withCredentials = true;

window.log = console.log.bind(console, "DEBUG:");
global.log = console.log.bind(console, "DEBUG:");

Vue.mixin({
  methods: {
    $adjustTnum: function (tnum) {
      if (
        (tnum.length == 34 || tnum.length == 30) &&
        tnum.substr(0, 3) == "420" &&
        tnum.substr(8, 1) == "9"
      )
        tnum = tnum.substr(10);
      else if (
        tnum.length == 34 &&
        tnum.substr(0, 3) == "420" &&
        tnum.substr(12, 1) == "9"
      )
        tnum = tnum.substr(14);
      else if (tnum.length == 34) tnum = tnum.substr(22);
      else if (tnum.length == 32) tnum = tnum.substr(16, 12);
      else if (tnum.length == 30) tnum = tnum.substr(8);
      else if (tnum.length == 22 && tnum.substr(0, 3) == "961")
        tnum = tnum.substr(7);
      else if (
        (tnum.length == 22 || tnum.length == 26) &&
        tnum.substr(0, 1) == "9"
      )
        tnum = tnum.substr(2);

      return tnum;
    },
    $sleep: async function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    $get: async function (url, arg) {
      return (await axios.get(url, arg)).data;
    },
    $getRO: async function (url, arg) {
      return (await axiosRO.get(url, arg)).data;
    },
    $post: async function (url, arg) {
      return (await axios.post(url, arg)).data;
    },
    $postRO: async function (url, arg) {
      return (await axios.post(url, arg)).data;
    },
    $warn(text) {
      return Vue.notify({ type: "warn", text: `<h3>${text}</h3>`, width: 700 });
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
