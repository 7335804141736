<template>
  <div class="container-fluid ">
    <b-tabs content-class="mt-3" class="mt-5">
      <!--      <b-tab >-->
      <!--        <template #title>-->
      <!--          <b-img src="https://i.ebayimg.com/images/g/wEwAAOSwKY5htJGS/s-l1600.jpg" widht="100" height="100"></b-img>-->
      <!--          I'm the first tab-->
      <!--        </template>-->
      <!--        <p>-->
      <!--          content-->
      <!--        </p>-->
      <!--      </b-tab>-->
      <b-tab v-for="(logi,logicode) of logisObj" @click="setLogi(logicode)">
        <template #title>
          <b-img width="80" height="80" :src="Object.values(logi)[0][1][0].img"/>
          <!--          {{ logi[0][0].title }}-->
        </template>
        <div class="accordion" role="tablist">
          <b-alert v-if="logicode==='REMAINDER'" show variant="warning">
            Below is the remainder after dividing. Match items, put on a rack, scan the barcode of the rack.
          </b-alert>
          <div v-for="[tnum,ordersIntheLogi] of logi" :key="tnum">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <div v-b-toggle="'accordion-' +logicode+ tnum" class="text-left"
                     :class="{'bg-success':scannedArr.includes(tnum)}">
                  <b-badge variant="primary" class="mr-2"><h6>{{ ordersIntheLogi.length }}<span
                    v-if="tnumsObj[tnum].length !== ordersIntheLogi.length">/{{ tnumsObj[tnum].length }}</span></h6>
                  </b-badge>
                  <b-badge variant="warning">
                    <b-img v-for="order of ordersIntheLogi" width="50" height="50" :src="order.img"/>
                  </b-badge>
                  <b-badge variant="secondary">
                    <b-img style="opacity:25%"
                           v-for="orderInTheBox of tnumsObj[tnum]" v-if="!ordersIntheLogi.some(order => order.idx === orderInTheBox.idx)" width="50"
                           height="50" :src="orderInTheBox.img"/>
                  </b-badge>
                  {{ tnum }}
                </div>
              </b-card-header>
              <b-collapse :id="'accordion-'+logicode+tnum" :ref="'accordion-'+logicode+tnum" accordion="my-accordion"
                          role="tabpanel" tabindex="0">
                <b-card-body>
                  <b-row class="mb-4">
                    <b-col cols="2" v-for="order of ordersIntheLogi" class="mx-2">
                      <b-img width="200" height="200" :src="order.img" fluid/>
                      <br>
                      {{ order.title }}
                    </b-col>
                  </b-row>
                  <b-alert show variant="danger"
                           v-if="!tnumsObj[tnum].some(orderInTheBox => ordersIntheLogi.map(order=>order.idx).includes( orderInTheBox.idx))"
                  >The following items will not be packed with this package.</b-alert>
                  <b-row>
                    <b-col cols="2" style="opacity:25%"
                           v-if="!ordersIntheLogi.some(order => order.idx === orderInTheBox.idx)"
                           v-for="orderInTheBox of tnumsObj[tnum]" class="mx-2">
                      <b-img width="200" height="200" :src="orderInTheBox.img" fluid/>
                      <br>
                      {{ orderInTheBox.title }}
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div style="height:100px"/>

    <b-navbar variant="primary" fixed="bottom">
      <b-navbar-nav class="mx-auto">
        <b-form @submit.prevent="scan">
          <b-input ref="input" v-model="input"></b-input>
        </b-form>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
const recognition = new webkitSpeechRecognition();
const synth = window.speechSynthesis;
const speak = word => {
  const utterThis = new SpeechSynthesisUtterance(word);
  utterThis.lang = "en-US";
  synth.speak(utterThis);
};

export default {
  components: {},
  data() {
    return {
      scannedArr: [],
      logisObj: {},
      tnumsObj: {},
      countryNames: {
        1: "USA",
        2: "JAPAN",
        3: "CHINA",
        4: "KOREA"
      },
      countryName: "",
      noDataList: [],
      noDataShortcuts: [],
      instruction: "",
      ws: {},
      glocingWs: {},
      loading: false,
      input: "",
      rackEntered: true,
      currentBoxes: [],
      type: "",
      errorInfo: "",
      boxes: [],
      midx: "",
      tnum: "",
      history: [],
      stock: [],
      currentLogicode: ""
    };
  },
  methods: {
    async startCapture(displayMediaOptions) {

      let captureStream = null;

      try {
        captureStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
      } catch(err) {
        console.error("Error: " + err);
      }
      return captureStream;
    },
    setLogi(logicode) {
      this.currentLogicode = logicode;
    },
    disableIME() {
      // this.$refs.input.$el.type='password'
      // this.$refs.input.$el.type='text'
    },
    async getDeliveryInfo() {
      const {logisObj, tnumsObj} = await this.$get(
        "/get-with-tracking-number/" + this.input
      );
      this.logisObj = logisObj;
      this.tnumsObj = tnumsObj;
      this.setLogi(Object.keys(this.logisObj)[0]);
      console.log(this.logisObj);
    },
    async scan() {
      this.$refs.input.$el.select();
      this.key = Date.now();
      let {input, key} = this;
      input = input.toUpperCase();

      let wsArgs = {key, type: "error", input};
      if (/^(420|421840)(19804|07606)([0-9]{4})?$|^0000999999/.test(input)) {
        this.type = "error";
        this.errorInfo = "POSTAL CODE is not allowed.";
        speak("POSTAL");
        return false;
        // this.errorInfo = "error";
        // this.submsg = "POSTAL CODE is not allowed.";
      } else if (/[^a-zA-Z0-9 ]/g.test(input)) {
        this.type = "error";
        this.errorInfo = "Non-alphabetic characters are not allowed.";
        speak("alphabet");
        return false;
      } else if (this.input.length === 1) {
        this.input = this.noDataShortcuts[this.input];
        this.scan();
        this.input = "";
        return false;
        // scanning a tracking number
      } else if (this.input.length >= 8) {
        // if it is initial status or defered or packed
        if (
          !Object.keys(this.logisObj).length ||
          Object.values(this.logisObj).some(
            box =>
              Object.values(box)[0].defer === 2 || Object.values(box)[0].weight
          )
        ) {
          const scanned = this.getDeliveryInfo();
          if (typeof scanned === "string") {
            this.type = scanned;
            this.countryName = "";
          } else if (typeof scanned === "object") {
            this.type = scanned.type;
            this.countryName = this.countryNames[scanned.countryId];
          }
          wsArgs = {key, type: "tnum", tnum: this.$adjustTnum(input)};
          // temporarily removes the info about rack,solo or join
          // wsArgs = { key, type: "tnum", tnum: input, rack: this.type };
          // if it is neither defered nor packed
        } else {
          //16,10,7
          const boxes = this.logisObj[this.currentLogicode];
          const currentBoxIndex = boxes.findIndex(
            box => box[1][0].tnum === this.input
          );
          if(currentBoxIndex===-1){
            alert('error')
            return false
          }
          boxes.splice(0, 0, boxes.splice(currentBoxIndex, 1)[0]);

          this.scannedArr.push(this.input);
          // this.$refs["accordion-" + this.currentLogicode + this.input].$el.focus();
          // jumpto (index) {
          //   window.setTimeout(function () {
          //     document.getElementById(index).scrollIntoView()
          //   }, 200)
          window.setTimeout(function () {
            // document.getElementById("accordion-" + this.currentLogicode + this.input).focus()
            // document.getElementById('accordion-LHKN290918511Z3130270355403606').scrollIntoView()
          });
          this.$root.$emit(
            "bv::toggle::collapse",
            "accordion-" + this.currentLogicode + this.input
          );
        }
      } else if ([3, 4].includes(this.input.length) && this.tnum) {
        this.type = "saved";
        this.rackEntered = true;
        //because there are splitting packages...
        for (let box of this.currentBoxes) {
          box.rack = this.input;
        }
        wsArgs = {key, type: "rack", tnum: this.tnum, rack: input};
      } else if (/^[0-9]{1,7}[a-zA-Z ]{1,2}$/.test(this.input)) {
        const midx = this.input.match(/[0-9]+/)[0];
        if (
          // if it is jiggu member's number display error
          document.domain === "admin.uniauc.com" &&
          midx > 112918 &&
          midx < 500000
        ) {
          this.type = "error";
          this.errorInfo = "Not a correct member number";
          if (this.type === "error") speak("member");
          return false;
        } else if (
          // if it is uniauc member's number display error
          document.domain === "admin.jiggujiggu.com" &&
          midx < 100000
        ) {
          this.type = "error";
          this.errorInfo = "Not a correct member number";
          if (this.type === "error") speak("member");
          return false;
        }
        if (
          !this.noDataShortcuts.includes(this.input) &&
          this.noDataList.includes(this.input)
        ) {
          this.noDataShortcuts.push(this.input);
        } else this.noDataList.push(this.input);
        this.type = "assigned";
        this.midx = this.input;
        this.instruction = this.input;
        wsArgs = {
          key,
          type: "idcode",
          tnum: this.tnum,
          idcode: input
        };
      } else {
        this.type = "error";
        this.errorInfo = "It's shorter than 8 characters";
        if (this.type === "error") speak("short");
        return false;
      }
      if (this.type === "error") speak(this.errorInfo);

      let value;
      if (this.type === "saved" || this.type === "assigned") {
        value = this.tnum + " " + this.input;
      } else {
        value = this.input;
      }

      // manually remove history item by scanning the stale barcode
      const oldItem = this.history.findIndex(item => item.value === value);
      if (oldItem > -1) this.history.splice(oldItem, 1);

      this.history.push({value, key: this.key});
    },
    initSocket() {
      // if user is running mozilla then use it's built-in WebSocket
      window.WebSocket = window.WebSocket || window.MozWebSocket;
      this.glocingWs = new WebSocket(
        "wss://www.glocing.com:8013/" + this.$route.params.pcno
      );
      this.ws = new WebSocket(global.baseWSURL);
      this.glocingWs.onmessage = msg => {
        this.input = msg.data + " ";
        this.scan();
      };
      this.ws.onerror = function (error) {
        console.log(error, "ws onerror");
        // an error occurred when sending/receiving data
      };
      this.ws.onclose = e => {
        console.log(e);
        setTimeout(() => {
          this.initSocket();

          console.log("reconnected");
        }, 1000);
      };
      this.ws.onopen = function () {
        setInterval(() => {
          this.ws.send("ping");
        }, 50 * 1000);
      }.bind(this);

      this.ws.onmessage = function (message) {
        try {
          let data = JSON.parse(message.data);
          if (data.key === "received") {
            const itemId = this.history.findIndex(
              item => item.key == data.value
            );
            this.history.splice(itemId, 1);
          } else if (data.key === "notice") {
            this.type = data.value;
            speak("mobile assigned");
            console.log(data);
          } else if (data.key === "scanned") {
            if (document.hasFocus()) {
              const elm = document.activeElement;
              if (elm.tagName == "INPUT") {
                this.input = data.value.replace(/\W/g, "") + " ";
                this.$refs.form.$el.submit();
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
        // handle incoming message
      }.bind(this);
    },
    clearAll() {
      if (confirm("really?")) {
        localStorage.clear();
        location.reload();
      }
    },
    async syncMissedTnums() {
      let inputs = [];
      this.history.forEach(elm => {
        const values = elm.value.replace(" ", ";");
        inputs = [...inputs, ...values.split(";")];
      });
      for (const input of inputs) {
        console.log(input);
        this.input = input;
        this.rackEntered = true;
        this.scan();
        await this.$sleep(3000);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.ws.close();
    next();
  },
  watch: {
    history(history) {
      localStorage.setItem("history", JSON.stringify(history));
    }
  },
  async created() {
  },
  mounted() {
    if (this.$route.params.tnum) {
      this.input = this.$route.params.tnum;
      this.scan();
    }
    this.$refs.input.$el.focus();
    const historyJSON = localStorage.getItem("history");
    if (historyJSON) this.history = JSON.parse(historyJSON);

    // Options for getDisplayMedia()

    var displayMediaOptions = {
      video: {
        cursor: "always"
      },
      audio: false
    };
    this.startCapture(displayMediaOptions)
  }
};
</script>
<style scoped>
.b-card {
  font-family: Courier serif;
}
</style>
