<template>
  <div>
    <b-form>
      <b-row>
        <b-col class="text-center">
          <b-btn @click="goto('solo')">Solo Mode</b-btn>
          <b-btn @click="goto('join')">Join Mode</b-btn>
          <div v-if="showRacks">
            <div>
              <b-btn
                v-for="memberGroupNo of memberGroupNo"
                :key="memberGroupNo"
                size="lg"
                @click="setMemberGroup(memberGroupNo)"
                :class="{ 'bg-info': memberGroupNo === currentMemberGroupNo }"
                >{{ memberGroupNo }}
              </b-btn>
            </div>
            <span
              v-for="rack of [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
              ]"
            >
              <b-btn
                size="lg"
                @click="setRack(rack)"
                :class="{ 'bg-info': rack === currentRack }"
                >{{ rack }}</b-btn
              >
            </span>
            <b-btn
              size="lg"
              v-if="selectedOrder.oldRack"
              @click="saveRack(selectedOrder, selectedOrder.oldRack)"
              >{{ selectedOrder.oldRack }}
            </b-btn>
            <b-btn size="lg" @click="saveRack(selectedOrder, 'XXX', currentKey)"
              >XXX</b-btn
            >
          </div>
          <div class="mb-2" v-show="currentRack">
            <b-btn
              v-for="floor of floors"
              :key="floor"
              size="lg"
              style="height: 70px"
              @click="setFloor(floor)"
              :class="{ 'bg-info': floor === currentFloor }"
              >{{ currentMemberGroupNo + currentRack + floor }}
            </b-btn>
            <b-btn style="height: 70px" size="lg" @click="showRacks = true"
              >←</b-btn
            >
          </div>
          <div class="mb-5" id="floorButtons" tabindex="1">
            <span v-for="floor2 of floors" class="border border-dark">
              <span v-if="currentFloor" v-for="column of columns" :key="column">
                <b-btn
                  size="lg"
                  style="height: 70px"
                  @click="
                    saveRack(
                      selectedOrder,
                      currentMemberGroupNo + currentRack + floor2 + column
                    )
                  "
                  :class="{
                    'bg-warning':
                      currentMemberGroupNo + currentRack + floor2 + column ===
                      selectedOrder.joinRack,
                    'bg-info':
                      currentMemberGroupNo + currentRack + floor2 + column ===
                      currentPuttingLocation,
                    'bg-success rounded':
                      currentMemberGroupNo + currentRack + floor2 + column ===
                      selectedOrder.rack,
                  }"
                  >{{
                    currentMemberGroupNo + currentRack + floor2 + column
                  }}</b-btn
                >
                <!--                <b-btn size="lg" style="height:70px"-->
                <!--                       @click="saveRack(selectedOrder,currentFloorDetail+column)"-->
                <!--                       :class="{'bg-warning':currentFloorDetail+column===selectedOrder.joinRack,'bg-info':currentFloorDetail+column===currentPuttingLocation,'bg-success rounded':currentFloorDetail+column === selectedOrder.rack}">{{-->
                <!--                    currentFloorDetail + column-->
                <!--                  }}</b-btn>-->
              </span>
            </span>
            <b-btn
              size="lg"
              @click="saveRack(selectedOrder, 'SOLO', currentKey)"
              >SOLO</b-btn
            >
          </div>
        </b-col>
      </b-row>
    </b-form>
    <!--    <b-table-simple class="table-bordered" responsive>-->
    <!--      <b-thead>-->
    <!--        <b-tr>-->
    <!--          <b-th>{{currentPickingRack}}</b-th>-->
    <!--          <b-th v-for="column of columns" :key="column">{{ column }}</b-th>-->
    <!--        </b-tr>-->
    <!--      </b-thead>-->
    <!--      <b-tbody>-->
    <!--        <b-tr v-for="floor of floors.slice().reverse()" :key="floor" v-if="rackTables[currentPickingRack]">-->
    <!--          <b-th>{{ floor }}</b-th>-->
    <!--          <template v-if="rackTables[currentPickingRack][floor]">-->
    <!--            <b-td v-if="rackTables[currentPickingRack][floor][column]"-->
    <!--                  v-for="column of columns" :key="column">-->
    <!--              <div v-for="(locationInfo,seq) of rackTables[currentPickingRack][floor][column]">-->
    <!--                <div-->
    <!--                  :class="{'border border-primary':locationInfo.idx === selectedIdx,'border rounded border-warning':getOrder(locationInfo.idx).logicode===currentLogicode && locationInfo.idx !== selectedIdx,'bg-info rounded':getOrder(locationInfo.idx).joinCnt === 1,'bg-success rounded':getOrder(locationInfo.idx).rackSaved}"-->
    <!--                  @click="selectOrder(getOrder(locationInfo.idx))">-->
    <!--                  <div>-->
    <!--                    <b>{{ getOrder(locationInfo.idx).oldRack.substr(2, 2) }}</b>-->
    <!--                    <b-badge class="ml-1" variant="warning">{{ getOrder(locationInfo.idx).shopName }}</b-badge>-->
    <!--                  </div>-->
    <!--                  <div>-->
    <!--                    <span v-html="tnumLastDigits(getOrder(locationInfo.idx).stnum)"></span>-->
    <!--                    {{ getOrder(locationInfo.idx).name }}-->
    <!--                    <b-badge variant="info" v-if="getOrder(locationInfo.idx).joinRack">-->
    <!--                      {{ getOrder(locationInfo.idx).joinRack }}-->
    <!--                    </b-badge>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </b-td>-->
    <!--            <b-td v-else></b-td>-->
    <!--          </template>-->
    <!--          <b-td v-else v-for="column of columns" :key="column"></b-td>-->
    <!--        </b-tr>-->
    <!--      </b-tbody>-->
    <!--    </b-table-simple>-->
    <div
      id="list"
      @submit.prevent
      class="items-list"
      style="height: 500px; overflow: scroll"
    >
      <span
        v-for="(currentOrder, currentKey) of orders"
        :key="currentOrder.idx"
        class="mb-2"
        :id="'order' + currentOrder.idx"
        :tabindex="currentOrder.idx"
      >
        <b-row
          :class="{
            'border border-warning':
              currentOrder.logicode === currentLogicode &&
              currentOrder.idx !== selectedIdx,
            'border border-primary': selectedIdx === currentOrder.idx,
            'bg-info': currentOrder.joinCnt === 1,
            'bg-success rounded': currentOrder.rackSaved,
          }"
        >
          <!--        <b-col cols="3">-->
          <!--          <b-btn @click="postMsg('o',currentOrder.idx)" variant="success">👌</b-btn>-->
          <!--          <b-btn @click="editMsg(logi)" variant="warning">✏️</b-btn>-->
          <!--        </b-col>-->
          <b-col
            cols="12"
            class="font-weight-bold"
            v-if="
              currentKey === 0 ||
              extractRackDetail(orders[currentKey - 1].oldRack) !==
                extractRackDetail(currentOrder.oldRack)
            "
          >
            <h4>
              <b-badge variant="info">{{
                extractRackDetail(currentOrder.oldRack)
              }}</b-badge>
            </h4>
          </b-col>

          <b-col cols="10" @click="selectOrder(currentOrder)">
            <span>
              <b-input
                v-focus="currentOrder.focus"
                @focus="currentOrder.focus = true"
                @blur="currentOrder.focus = false"
                @change="postMsg(currentOrder.savemsg, currentOrder.idx)"
                v-show="
                  currentOrder.savemsg &&
                  currentOrder.savemsg !== 'o' &&
                  currentOrder.savemsg !== '.'
                "
                v-model="currentOrder.savemsg"
              ></b-input>
            </span>
            <span>
              <span v-show="currentOrder.oldRack !== currentOrder.rack"
                >{{ currentOrder.oldRack }}→</span
              ><b>{{ currentOrder.rack }}</b>
            </span>
            <span
              class="mx-1"
              v-html="tnumLastDigits(currentOrder.stnum)"
            ></span>
            <b-badge variant="primary" v-html="joinSeq(currentOrder)"></b-badge>
            <b-badge variant="info">{{ currentOrder.joinRack }}</b-badge>
            <b-badge variant="warning">{{ currentOrder.shopName }}</b-badge>
            <span>
              {{ currentOrder.name }}
              {{ currentOrder.name2 }}
              {{ currentOrder.midx }}-{{
                currentOrder.logicode.substr(
                  currentOrder.logicode.length - 2,
                  2
                )
              }}
              <span v-if="currentOrder.portion">
                <b-badge variant="danger"
                  >divide: {{ currentOrder.portion }}</b-badge
                >
              </span>
            </span>
          </b-col>
          <b-col cols="1">
            <a
              class="btn"
              target="_blank"
              :href="
                origin +
                '/admin/logis_list.php?p=0326&searchKey=idx;orders&searchVal=' +
                currentOrder.idx
              "
              >🔗</a
            >
          </b-col>
        </b-row>
      </span>
    </div>
  </div>
</template>

<script>
import { focus } from "vue-focus";

const synth = window.speechSynthesis;
const speak = (word) => {
  const utterThis = new SpeechSynthesisUtterance(word);
  utterThis.lang = "en-US";
  synth.speak(utterThis);
};

export default {
  directives: { focus },
  components: {},
  data() {
    return {
      // columns: [0, 1, 2, 3, 4, 5],
      columns: ["A", "C", "E"],
      // columns: ["A", "B", "C", "D", "E", "F"],
      floors: ["1", "2", "3", "4", "5", "6"],
      memberGroupNo: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      currentPickingRack: "",
      rackTables: {},
      showRacks: true,
      domain: "",
      selectedOrder: {},
      selectedIdx: "",
      currentMemberGroupNo: "",
      currentRack: "",
      currentFloor: "",
      currentPuttingLocation: "",
      currentLogicode: "",
      rack: "",
      saveMsgIdx: 0,
      saveMsg: "",
      orders: [],
      ws: {},
      glocingWs: {},
      loading: false,
      input: "",
      rackEntered: true,
      currentBoxes: [],
      userid: "",
      type: "",
      errorInfo: "",
      boxes: [],
      midx: "",
      tnum: "",
      history: [],
      stock: [],
    };
  },
  computed: {
    currentFloorDetail(currentFloorDetail) {
      return this.currentMemberGroupNo + this.currentRack + this.currentFloor;
    },
  },
  methods: {
    async init() {
      this.origin = location.origin.replace("admin", "www");
      this.loading = true;
      const data = await this.$get(
        "/to-pick/" +
          this.$route.params.region +
          "?mode=" +
          this.$route.query.mode
      );
      this.orders = data.orders;
      this.rackTables = data.rackTables;
      this.orders.forEach((order) => {
        order.focus = false;
        order.selectedIdx = 0;
        order.rackSaved = false;
        order.oldRack = order.rack;
        order.joinRack = "";
      });
      console.log(this.items);
      this.loading = false;
    },
    goto(mode) {
      this.$route.query.mode = mode;
      this.$router.push("?mode=" + mode);
      this.init();
    },
    joinSeq(currentOrder) {
      const joinOrders = this.orders
        .filter((order) => order.logicode === currentOrder.logicode)
        .map((order) => order.idx);
      const totalJoinOrderLength = joinOrders.length;
      const joinOrderSeq = joinOrders.indexOf(currentOrder.idx) + 1;
      return `<b>${joinOrderSeq}</b>/${totalJoinOrderLength}`;
    },
    getOrder(idx) {
      return this.orders.find((order) => order.idx === idx);
    },
    getLetter(number) {
      return (number + 10).toString(36).toUpperCase();
    },
    extractRackDetail(rack) {
      return rack.substr(0, 2);
    },
    extractMemberGroup(rack) {
      return rack.substr(0, 1);
    },
    extractRack(rack) {
      return rack.length === 4 ? rack.substr(1, 1) : rack.substr(0, 1);
    },
    extractFloor(rack) {
      return rack.length === 4 ? rack.substr(2, 1) : rack.substr(1, 1);
    },
    selectOrder(order) {
      this.selectedIdx = order.idx;
      this.selectedOrder = order;
      if (
        order.joinRack &&
        this.extractRackDetail(order.joinRack) ===
          this.extractRackDetail(this.currentPuttingLocation)
      ) {
        this.setMemberGroup(this.extractMemberGroup(order.joinRack));
        this.setRack(this.extractRack(order.joinRack));
        this.setFloor(this.extractFloor(order.joinRack));
      }
      if (!this.currentPickingRack)
        this.currentPickingRack = this.extractRackDetail(order.oldRack);
      this.currentLogicode = order.logicode;

      document.getElementById("order" + order.idx).focus();
    },
    setMemberGroup(memberGroupNo) {
      this.currentMemberGroupNo = memberGroupNo;
    },
    setRack(rack) {
      this.currentRack = rack;
      this.showRacks = false;
    },
    setFloor(floor) {
      this.showRacks = false;
      this.currentFloor = floor;
    },
    async saveRack(currentOrder, location) {
      this.$warn(location);
      // if (currentKey !== this.orders.length - 1) this.selectOrder(this.orders[currentKey + 1])

      const currentKey = this.orders.findIndex(
        (order) => order.idx === currentOrder.idx
      );
      if (currentKey !== this.orders.length - 1) {
        for (
          let nextKey = currentKey + 1;
          nextKey < this.orders.length - 1;
          nextKey++
        ) {
          // the logic skipping singleton order
          // if (
          //   this.orders[nextKey].joinCnt === 1 &&
          //   this.extractRackDetail(this.orders[nextKey].rack) ===
          //   this.currentPickingRack
          // )
          //   continue;
          // else {
          this.selectOrder(this.orders[nextKey]);
          break;
          // }
        }
      }

      const posted = await this.$post("/save-rack", {
        stnum: currentOrder.stnum,
        rack: location,
      });
      if (!currentOrder.oldRack) currentOrder.oldRack = currentOrder.rack;
      currentOrder.rack = location;

      // display this rack to other joining boxes.
      this.orders
        .filter(
          (originalOrder) =>
            originalOrder.logicode === currentOrder.logicode &&
            originalOrder.idx !== currentOrder.idx
        )
        .forEach((filteredOrder) => {
          filteredOrder.joinRack = currentOrder.rack;
        });
      this.currentPuttingLocation = location;
      currentOrder.rackSaved = true;
    },
    async onEdit() {
      await this.postMsg(this.saveMsg, this.saveMsgIdx);
    },
    editMsg(logi) {
      order.savemsg = " ";
      order.focus = true;
      // this.saveMsgIdx = order.idx;
      // this.$bvModal.show('saveMsgModal');
    },
    async postMsg(savemsg, idx) {
      await this.$post("/save-msg", { savemsg, idx });
    },
    tnumLastDigits(tnum) {
      let last4 = tnum.substr(tnum.length - 4);
      const last4half1 = last4.substr(0, 2);
      const last4half2 = last4.substr(2);

      if (last4half2 === "01") {
        return `<b class="text-danger">${last4half1}</b>${last4half2}`;
      } else {
        return `${last4half1}<b class="text-danger">${last4half2}</b>`;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // this.ws.close();
    next();
  },
  watch: {
    history(history) {
      localStorage.setItem("history", JSON.stringify(history));
    },
  },
  async created() {
    this.init();
  },
  mounted() {},
};
</script>
<style scoped>
.b-card {
  font-family: Courier serif;
}

.border-warning {
  border-width: 2px !important;
  /*border-bottom: none !important;*/
}

.badge {
  font-size: 20px;
}

.items-list {
  /*height:300px;overflow: scroll*/
}
</style>
