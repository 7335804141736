<template>
  <div class="container-fluid" @keydown="$refs.input.$el.focus()" @click="$refs.input.$el.focus()"
       :style="{opacity:!dataLoaded||!socketOpen.jg||!socketOpen.uni ? 0.5 : 1}">
    <div v-if="!socketOpen.uni">UNI server connecting...</div>
    <div v-if="!socketOpen.jg">JG server connecting...</div>
    <div v-if="!dataLoaded">Tracking data loading...</div>
    <h3>
      <span v-for="(tnum,id) of noDataShortcuts">
        <badge>{{ id }}:{{ tnum }}</badge>
      </span>
    </h3>
    <h3 v-for="(siteHistory,site) in history">
      <badge>{{site.toUpperCase()}}:</badge>
        <badge v-for="item in siteHistory" :key="item.key">{{ item.value }}</badge>
    </h3>
    <h3>
    <button size="lg" @click="syncMissedTnums">Sync</button>
    <button size="lg" @click="clearAll">Clear</button>
    </h3>

    <b-form style="position:relative" ref="form" @submit.prevent="scan">
      <badge style="position:absolute;font-size:30px"> {{ input }}</badge>
      <b-input style="position:relative;z-index:-1;" @change="disableIME" type="password" ref="input" v-model="input"
               autocomplete="off"></b-input>
    </b-form>
    <b-card class="mt-3 bg-light">
      <b-row v-show="type==='join'" style="font-size:100px">
        <b-col sm="6" xl="4" :key="box.id" v-for="box in boxes" v-if="box.tnum !== tnum">
          {{ countryName }}
          {{ box.rack }}
          <span v-if="box.tnum.substr(box.tnum.length-2,2) === '01' && box.tnum.substr(0,3) === 'TBA'"
                class="text-danger">
            <b-label style="font-size:80px">{{ box.tnum.substr(box.tnum.length - 4, 2) }}</b-label><b-label
            style="font-size:14px">01</b-label>
          </span>
          <span v-else class="text-danger">
            {{ box.tnum.substr(box.tnum.length - 2, 2) }}
          </span>
        </b-col>
      </b-row>
      <div v-show="type!=='join'" class="text-capitalize text-center" style="font-size:200px">
        {{ type }}
        {{ countryName }}
        {{ type === 'error' ? ":" + errorInfo : '' }}
        <div style="font-size:50px">
          {{ instruction }}
        </div>
      </div>
      <div v-show="type==='no code'" class="text-capitalize text-center" style="font-size:100px">
        {{ tnum }}
        <badge>{{ midx }}</badge>
      </div>
    </b-card>
  </div>
</template>

<script>
const recognition = new webkitSpeechRecognition();
const synth = window.speechSynthesis;
const speak = word => {
  const utterThis = new SpeechSynthesisUtterance(word);
  utterThis.lang = "en-US";
  synth.speak(utterThis);
};

export default {
  components: {},
  data() {
    return {
      countryNames: {
        1: "USA",
        2: "JAPAN",
        3: "CHINA",
        4: "KOREA"
      },
      countryName: "",
      noDataList: [],
      noDataShortcuts: [],
      instruction: "",
      ws: {},
      glocingWs: {},
      dataLoaded:false,
      socketOpen:{uni:false,jg:false},
      input: "",
      rackEntered: true,
      currentBoxes: [],
      type: "",
      errorInfo: "",
      boxes: [],
      midx: "",
      tnum: "",
      history: { jg: [], uni: [] },
      stock: []
    };
  },
  methods: {
    sendData(site, value, args) {
      if (site === "uni") this.ws.send(JSON.stringify(args));
      else this.wsJg.send(JSON.stringify(args));
      // manually remove history item by scanning the stale barcode
      const oldItem = this.history[site].findIndex(
        item => item.value === value
      );
      if (oldItem > -1) this.history[site].splice(oldItem, 1);

      this.history[site].push({ value, key: this.key });
    },
    disableIME() {
      // this.$refs.input.$el.type='password'
      // this.$refs.input.$el.type='text'
    },
    scanTnum() {
      if (
        this.tnum !== this.$adjustTnum(this.input) &&
        ["error", "solo", "fast"].includes(this.type) === false &&
        this.rackEntered === false
      ) {
        return "no code";
      }
      this.tnum = this.$adjustTnum(this.input);
      this.rackEntered = false;
      let found = 0;
      this.currentBoxes = [];
      for (const logi of this.stock) {
        const scannedItem = logi.orders.find(
          order =>
            order.tnum &&
            order.tnum.toUpperCase().includes(this.tnum.toUpperCase())
        );
        if (scannedItem) {
          this.midx = logi.member ? logi.member.midx : "A";
          if (logi.member && logi.member.midx === 126961) this.midx = "B";
          found++;
          this.currentBoxes.push(scannedItem);
          scannedItem.status = 4;
          // all items arrived
          if (logi.orders.every(order => order.status == 4)) {
            if (logi.defer == 2) {
              return (
                this.midx
                  .toString()
                  .substr(this.midx.toString().length - 1, 1) +
                " " +
                "rack"
              );
            } else if (logi.orders.length === 1) {
              if (logi.carrierR === "fast") return "fast";
              else return { type: "solo", countryId: logi.countryId };
            } else {
              this.boxes = logi.orders;
              // phone mirroring
              this.glocingWs.send(JSON.stringify(this.boxes));

              return { type: "join", countryId: logi.countryId };
            }
            // some items not yet arrived
          } else {
            return (
              this.midx.toString().substr(this.midx.toString().length - 1, 1) +
              " rack "
            );
          }
        }
      }
      if (found === 0) {
        return "no data";
      } else if (found > 1) {
        return "split";
      }
    },
    async scan() {
      this.$refs.input.$el.select();
      this.key = Date.now();
      let { input, key } = this;
      input = input.toUpperCase();

      let wsArgs = { key, type: "error", input };
      if (/^(420|421840)(19804|07606)([0-9]{4})?$|^0000999999/.test(input)) {
        this.type = "error";
        this.errorInfo = "POSTAL CODE is not allowed.";
        speak("POSTAL");
        return false;
        // this.errorInfo = "error";
        // this.submsg = "POSTAL CODE is not allowed.";
      } else if (/[^a-zA-Z0-9 ]/g.test(input)) {
        this.type = "error";
        this.errorInfo = "Non-alphabetic characters are not allowed.";
        speak("alphabet");
        return false;
      } else if (this.input.length === 1) {
        this.input = this.noDataShortcuts[this.input];
        this.scan();
        this.input = "";
        return false;
      } else if (this.input.length >= 8) {
        const scanned = this.scanTnum();
        if (typeof scanned === "string") {
          this.type = scanned;
          this.countryName = "";
        } else if (typeof scanned === "object") {
          this.type = scanned.type;
          this.countryName = this.countryNames[scanned.countryId];
        }
        wsArgs = { key, type: "tnum", tnum: this.$adjustTnum(input) };
        // temporarily removes the info about rack,solo or join
        // wsArgs = { key, type: "tnum", tnum: input, rack: this.type };
      } else if ([3, 4].includes(this.input.length) && this.tnum) {
        this.type = "saved";
        this.rackEntered = true;
        //because there are splitting packages...
        for (let box of this.currentBoxes) {
          box.rack = this.input;
        }
        wsArgs = { key, type: "rack", tnum: this.tnum, rack: input };
      } else if (/^[0-9]{1,7}[a-zA-Z ]{1,2}$/.test(this.input)) {
        const midx = this.input.match(/[0-9]+/)[0];
        // if (
        //   // if it is jiggu member's number display error
        //   document.domain === "admin.uniauc.com" &&
        //   midx > 112918 &&
        //   midx < 500000
        // ) {
        //   debugger;
        //   this.type = "error";
        //   this.errorInfo = "Not a correct member number";
        //   if (this.type === "error") speak("member");
        //   return false;
        // } else if (
        //   // if it is uniauc member's number display error
        //   document.domain === "admin.jiggujiggu.com" &&
        //   midx < 100000
        // ) {
        //   this.type = "error";
        //   this.errorInfo = "Not a correct member number";
        //   if (this.type === "error") speak("member");
        //   return false;
        // }
        if (
          !this.noDataShortcuts.includes(this.input) &&
          this.noDataList.includes(this.input)
        ) {
          this.noDataShortcuts.push(this.input);
        } else this.noDataList.push(this.input);
        this.type = "assigned";
        this.midx = this.input;
        this.instruction = this.input;
        wsArgs = {
          key,
          type: "idcode",
          tnum: this.tnum,
          idcode: input
        };
      } else {
        this.type = "error";
        this.errorInfo = "It's shorter than 8 characters";
        if (this.type === "error") speak("short");
        return false;
      }
      if (this.type === "error") speak(this.errorInfo);

      let value;
      if (this.type === "saved" || this.type === "assigned") {
        value = this.tnum + " " + this.input;
      } else {
        value = this.input;
      }

      if (wsArgs.idcode) {
        const idcode = wsArgs.idcode.replace(/[^0-9]/g, "");
        if (idcode < 100000 || idcode > 500000) {
          this.sendData("uni",value,wsArgs)
        } else {
          if (idcode > 112918 || wsArgs.idcode.split("").pop() === " ") {
            this.sendData("jg",value,wsArgs)
          } else
            this.sendData("uni",value,wsArgs)
        }
      } else {
        this.sendData("uni",value,wsArgs)
        this.sendData("jg",value,wsArgs)
      }
      speak(this.type + " " + (this.countryName || ""));
      if (this.type === "no data") {
        // this.instruction = "Say the member number";
        // recognition.start();
      }
    },
    initSocket() {
      // if user is running mozilla then use it's built-in WebSocket
      window.WebSocket = window.WebSocket || window.MozWebSocket;
      this.glocingWs = new WebSocket(
        "wss://www.glocing.com:8013/" + this.$route.params.pcno
      );
      this.ws = new WebSocket(global.baseWSURL);
      this.glocingWs.onmessage = msg => {
        this.input = msg.data + " ";
        this.scan();
      };
      this.ws.onerror = function(error) {
        console.log(error, "ws onerror");
        // an error occurred when sending/receiving data
      };
      this.ws.onclose = e => {
        console.log(e);
        this.$warn('UNI server closed')
        setTimeout(() => {
          this.initSocket();

          console.log("reconnected");
        }, 1000);
      };
      this.ws.onopen = function() {
        this.socketOpen.uni=true
        this.$warn("UNI server connected")
        setInterval(() => {
          this.ws.send("ping");
        }, 50 * 1000);
      }.bind(this);

      this.ws.onmessage = function(message) {
        try {
          let data = JSON.parse(message.data);
          if (data.key === "received") {
            const itemId = this.history["uni"].findIndex(
              item => item.key == data.value
            );
            this.history["uni"].splice(itemId, 1);
          } else if (data.key === "notice") {
            this.type = data.value;
            speak("mobile assigned");
            console.log(data);
          } else if (data.key === "scanned") {
            if (document.hasFocus()) {
              const elm = document.activeElement;
              if (elm.tagName == "INPUT") {
                this.input = data.value.replace(/\W/g, "") + " ";
                this.$refs.form.$el.submit();
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
        // handle incoming message
      }.bind(this);
    },
    initJgSocket() {
      // if user is running mozilla then use it's built-in WebSocket
      window.WebSocket = window.WebSocket || window.MozWebSocket;
      this.wsJg = new WebSocket("wss://api.jiggujiggu.com");
      this.glocingWs.onmessage = msg => {
        this.input = msg.data + " ";
        this.scan();
      };
      this.wsJg.onerror = function(error) {
        console.log(error, "ws onerror");
        // an error occurred when sending/receiving data
      };
      this.wsJg.onclose = e => {
        console.log(e);
        this.$warn('UNI server closed')

        setTimeout(() => {
          this.initJgSocket();

          console.log("reconnected");
        }, 1000);
      };
      this.wsJg.onopen = function() {
        this.socketOpen.jg=true
        this.$warn("JIGGU server connected.")
        setInterval(() => {
          this.wsJg.send("ping");
        }, 50 * 1000);
      }.bind(this);

      this.wsJg.onmessage = function(message) {
        try {
          let data = JSON.parse(message.data);
          if (data.key === "received") {
            const itemId = this.history["jg"].findIndex(
              item => item.key == data.value
            );
            this.history["jg"].splice(itemId, 1);
          } else if (data.key === "notice") {
            this.type = data.value;
            speak("mobile assigned");
            console.log(data);
          } else if (data.key === "scanned") {
            if (document.hasFocus()) {
              const elm = document.activeElement;
              if (elm.tagName == "INPUT") {
                this.input = data.value.replace(/\W/g, "") + " ";
                this.$refs.form.$el.submit();
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
        // handle incoming message
      }.bind(this);
    },
    clearAll() {
      if (confirm("really?")) {
        localStorage.clear();
        location.reload();
      }
    },
    async syncMissedTnums() {
      let inputs = [];
      for (const site of ["uni", "jg"])
        this.history[site].forEach(elm => {
          const values = elm.value.replace(" ", ";");
          inputs = [...inputs, ...values.split(";")];
        });
      for (const input of inputs) {
        console.log(input);
        this.input = input;
        this.rackEntered = true;
        this.scan();
        await this.$sleep(3000);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.ws.close();
    this.wsJg.close();
    next();
  },
  watch: {
    history(history) {
      localStorage.setItem("history.uni", JSON.stringify(history["uni"]));
      localStorage.setItem("history.jg", JSON.stringify(history["jg"]));
    }
  },
  async created() {
    this.$get(
      "/stocking/union/" + this.$route.params.region
    ).then(res=>{
      this.stock=res
      this.dataLoaded = true
      this.$warn("Tracking data finished loading.")
      console.log('all data loaded')

    })
    this.initSocket();
    this.initJgSocket();

    recognition.lang = "en-US";
    recognition.onresult = event => {
      const finalResult = event.results[0][0].transcript;
      this.input = finalResult.replace(/\s*/g, "") + " ";
      this.scan();
      this.input = "";
      // const finalReseult=Object.values(event.results).find(result=>result.isFinal===true)
    };
  },
  mounted() {
    this.$refs.input.$el.focus();
    for (const site of ["uni", "jg"]) {
      const historyJSON = localStorage.getItem(`history.${site}`);
      if (historyJSON) this.history[site] = JSON.parse(historyJSON);
    }
  }
};
</script>
<style scoped>
.b-card {
  font-family: Courier serif;
}
</style>
