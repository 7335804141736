var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-btn',{on:{"click":function($event){return _vm.goto('solo')}}},[_vm._v("Solo Mode")]),_c('b-btn',{on:{"click":function($event){return _vm.goto('join')}}},[_vm._v("Join Mode")]),(_vm.showRacks)?_c('div',[_c('div',_vm._l((_vm.memberGroupNo),function(memberGroupNo){return _c('b-btn',{key:memberGroupNo,class:{ 'bg-info': memberGroupNo === _vm.currentMemberGroupNo },attrs:{"size":"lg"},on:{"click":function($event){return _vm.setMemberGroup(memberGroupNo)}}},[_vm._v(_vm._s(memberGroupNo)+" ")])}),1),_vm._l(([
              'A',
              'B',
              'C',
              'D',
              'E',
              'F',
              'G',
              'H',
              'I',
              'J',
              'K' ]),function(rack){return _c('span',[_c('b-btn',{class:{ 'bg-info': rack === _vm.currentRack },attrs:{"size":"lg"},on:{"click":function($event){return _vm.setRack(rack)}}},[_vm._v(_vm._s(rack))])],1)}),(_vm.selectedOrder.oldRack)?_c('b-btn',{attrs:{"size":"lg"},on:{"click":function($event){return _vm.saveRack(_vm.selectedOrder, _vm.selectedOrder.oldRack)}}},[_vm._v(_vm._s(_vm.selectedOrder.oldRack)+" ")]):_vm._e(),_c('b-btn',{attrs:{"size":"lg"},on:{"click":function($event){return _vm.saveRack(_vm.selectedOrder, 'XXX', _vm.currentKey)}}},[_vm._v("XXX")])],2):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentRack),expression:"currentRack"}],staticClass:"mb-2"},[_vm._l((_vm.floors),function(floor){return _c('b-btn',{key:floor,class:{ 'bg-info': floor === _vm.currentFloor },staticStyle:{"height":"70px"},attrs:{"size":"lg"},on:{"click":function($event){return _vm.setFloor(floor)}}},[_vm._v(_vm._s(_vm.currentMemberGroupNo + _vm.currentRack + floor)+" ")])}),_c('b-btn',{staticStyle:{"height":"70px"},attrs:{"size":"lg"},on:{"click":function($event){_vm.showRacks = true}}},[_vm._v("←")])],2),_c('div',{staticClass:"mb-5",attrs:{"id":"floorButtons","tabindex":"1"}},[_vm._l((_vm.floors),function(floor2){return _c('span',{staticClass:"border border-dark"},_vm._l((_vm.columns),function(column){return (_vm.currentFloor)?_c('span',{key:column},[_c('b-btn',{class:{
                  'bg-warning':
                    _vm.currentMemberGroupNo + _vm.currentRack + floor2 + column ===
                    _vm.selectedOrder.joinRack,
                  'bg-info':
                    _vm.currentMemberGroupNo + _vm.currentRack + floor2 + column ===
                    _vm.currentPuttingLocation,
                  'bg-success rounded':
                    _vm.currentMemberGroupNo + _vm.currentRack + floor2 + column ===
                    _vm.selectedOrder.rack,
                },staticStyle:{"height":"70px"},attrs:{"size":"lg"},on:{"click":function($event){return _vm.saveRack(
                    _vm.selectedOrder,
                    _vm.currentMemberGroupNo + _vm.currentRack + floor2 + column
                  )}}},[_vm._v(_vm._s(_vm.currentMemberGroupNo + _vm.currentRack + floor2 + column))])],1):_vm._e()}),0)}),_c('b-btn',{attrs:{"size":"lg"},on:{"click":function($event){return _vm.saveRack(_vm.selectedOrder, 'SOLO', _vm.currentKey)}}},[_vm._v("SOLO")])],2)],1)],1)],1),_c('div',{staticClass:"items-list",staticStyle:{"height":"500px","overflow":"scroll"},attrs:{"id":"list"},on:{"submit":function($event){$event.preventDefault();}}},_vm._l((_vm.orders),function(currentOrder,currentKey){return _c('span',{key:currentOrder.idx,staticClass:"mb-2",attrs:{"id":'order' + currentOrder.idx,"tabindex":currentOrder.idx}},[_c('b-row',{class:{
          'border border-warning':
            currentOrder.logicode === _vm.currentLogicode &&
            currentOrder.idx !== _vm.selectedIdx,
          'border border-primary': _vm.selectedIdx === currentOrder.idx,
          'bg-info': currentOrder.joinCnt === 1,
          'bg-success rounded': currentOrder.rackSaved,
        }},[(
            currentKey === 0 ||
            _vm.extractRackDetail(_vm.orders[currentKey - 1].oldRack) !==
              _vm.extractRackDetail(currentOrder.oldRack)
          )?_c('b-col',{staticClass:"font-weight-bold",attrs:{"cols":"12"}},[_c('h4',[_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(_vm._s(_vm.extractRackDetail(currentOrder.oldRack)))])],1)]):_vm._e(),_c('b-col',{attrs:{"cols":"10"},on:{"click":function($event){return _vm.selectOrder(currentOrder)}}},[_c('span',[_c('b-input',{directives:[{name:"focus",rawName:"v-focus",value:(currentOrder.focus),expression:"currentOrder.focus"},{name:"show",rawName:"v-show",value:(
                currentOrder.savemsg &&
                currentOrder.savemsg !== 'o' &&
                currentOrder.savemsg !== '.'
              ),expression:"\n                currentOrder.savemsg &&\n                currentOrder.savemsg !== 'o' &&\n                currentOrder.savemsg !== '.'\n              "}],on:{"focus":function($event){currentOrder.focus = true},"blur":function($event){currentOrder.focus = false},"change":function($event){return _vm.postMsg(currentOrder.savemsg, currentOrder.idx)}},model:{value:(currentOrder.savemsg),callback:function ($$v) {_vm.$set(currentOrder, "savemsg", $$v)},expression:"currentOrder.savemsg"}})],1),_c('span',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(currentOrder.oldRack !== currentOrder.rack),expression:"currentOrder.oldRack !== currentOrder.rack"}]},[_vm._v(_vm._s(currentOrder.oldRack)+"→")]),_c('b',[_vm._v(_vm._s(currentOrder.rack))])]),_c('span',{staticClass:"mx-1",domProps:{"innerHTML":_vm._s(_vm.tnumLastDigits(currentOrder.stnum))}}),_c('b-badge',{attrs:{"variant":"primary"},domProps:{"innerHTML":_vm._s(_vm.joinSeq(currentOrder))}}),_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(_vm._s(currentOrder.joinRack))]),_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(_vm._s(currentOrder.shopName))]),_c('span',[_vm._v(" "+_vm._s(currentOrder.name)+" "+_vm._s(currentOrder.name2)+" "+_vm._s(currentOrder.midx)+"-"+_vm._s(currentOrder.logicode.substr( currentOrder.logicode.length - 2, 2 ))+" "),(currentOrder.portion)?_c('span',[_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("divide: "+_vm._s(currentOrder.portion))])],1):_vm._e()])],1),_c('b-col',{attrs:{"cols":"1"}},[_c('a',{staticClass:"btn",attrs:{"target":"_blank","href":_vm.origin +
              '/admin/logis_list.php?p=0326&searchKey=idx;orders&searchVal=' +
              currentOrder.idx}},[_vm._v("🔗")])])],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }