<template>
  <div class="container-fluid" style="">
    <b-row class=""  >
      <b-col>
        <b-input placeholder="PC Name" @change="savePcName" type="text" onclick="this.select()"
                 v-model="pcName"/>
      </b-col>
      <b-col>
        <b-input placeholder="Member No." type="number" onclick="this.select()" v-model="midx"
                 @change="sendMidx()"/>
      </b-col>
      <b-col>
        <b-btn @click="sleepToggle" >{{stateText}}</b-btn>

      </b-col>
    </b-row>
    <b-card class="mt-3 bg-light">
      <b-row style="font-size:50px">
        <b-col sm="6" xl="4" :key="box.tnum" v-for="box in boxes" >
          {{userid}}
          {{box.rack}}
          <span v-if="box.tnum.substr(box.tnum.length-3,3) === '000'" class="text-danger">
            <b-label style="font-size:80px">{{box.tnum.substr(box.tnum.length-5,2)}}</b-label><b-label
            style="font-size:11px">000</b-label>
          </span>
          <span v-else class="text-danger">
            {{box.tnum.substr(box.tnum.length-2,2)}}
          </span>
        </b-col>
      </b-row>
      <div v-show="type!=='join'" class="text-capitalize text-center" style="font-size:200px">
        {{userid}}
        {{type}}
        {{type==='error'?":"+errorInfo:''}}
      </div>
      <div v-show="type==='no code'" class="text-capitalize text-center" style="font-size:100px">
        {{tnum}}
        <badge>{{midx}}</badge>
      </div>
    </b-card>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      type: "",
      userid: "",
      tnum: "",
      pcName: '',
      midx: '',
      boxes: [],
      ws: {},
      glocingWs: {},
      sleepAllowed: false,
      stateText: "OFF O"
    };
  },
  methods: {
    sleepToggle() {
      this.sleepAllowed = !this.sleepAllowed;
      if (this.sleepAllowed) {
        console.log('insomnia turning on')
        this.vueInsomnia().on();
        this.stateText = "OFF X";
      } else {
        console.log('insomnia turning off')
        this.vueInsomnia().off();
        this.stateText = "OFF O";
      }
    },
    disableIME() {
      // this.$refs.input.$el.type='password'
      // this.$refs.input.$el.type='text'
    },
    scanTnum() {
      if (
        this.tnum !== this.$adjustTnum(this.input) &&
        ["error", "solo", "fast", "join"].includes(this.type) === false &&
        this.rackEntered === false
      ) {
        return "no code";
      }
      this.tnum = this.$adjustTnum(this.input);
      this.rackEntered = false;
      let found = 0;
      this.currentBoxes = [];
      for (const logi of this.stock) {
        const box = logi.orders.find(
          order =>
            order.tnum &&
            order.tnum.toUpperCase().includes(this.tnum.toUpperCase())
        );
        if (box) {
          this.midx = logi.member.midx;
          found++;
          this.currentBoxes.push(box);
          box.status = 4;
          // all items arrived
          if (logi.orders.every(order => order.status == 4)) {
            if (logi.defer == 2) {
              return (
                logi.member.midx
                  .toString()
                  .substr(logi.member.midx.toString().length - 1, 1) + "rack"
              );
            } else if (logi.orders.length === 1) {
              if (logi.carrierR === "fast") return "fast";
              else return { type: "solo", userid: logi.userid };
            } else {
              this.boxes = logi.orders;
              this.boxes.map(box => {
                return { racks_phone: box.rack };
              });
              debugger;
              this.glocingWs.send(JSON.stringify(this.boxes));

              return { type: "join", userid: logi.userid };
            }
          } else {
            return (
              logi.member.midx
                .toString()
                .substr(logi.member.midx.toString().length - 1, 1) + "rack "
            );
          }
        }
      }
      if (found === 0) {
        return "no data";
      } else if (found > 1) {
        return "split";
      }
    },
    async scan() {
      this.$refs.input.$el.select();
      this.key = Date.now();
      let { input, key } = this;
      input = input.toUpperCase();

      let wsArgs = { key, type: "error", input };

      if (/^(420|421840)(19804|07666|07670)([0-9]{4})?$|^0000999999/.test(input)) {
        this.type = "error";
        this.errorInfo = "POSTAL CODE is not allowed.";
        // this.errorInfo = "error";
        // this.submsg = "POSTAL CODE is not allowed.";
      } else if (/[^a-zA-Z0-9 ]/g.test(input)) {
        this.type = "error";
        this.errorInfo = "Non-alphabetic characters are not allowed.";
      } else if (this.input.length >= 8) {
        const scanned = this.scanTnum();
        if (typeof scanned === "string") {
          this.type = scanned;
          this.userid = "";
        } else if (typeof scanned === "object") {
          this.type = scanned.type;
          this.userid = scanned.userid;
        }
        wsArgs = { key, type: "tnum", tnum: input, rack: this.type };
      } else if ([3, 4].indexOf(this.input.length) > -1 && this.tnum) {
        this.type = "saved";
        this.rackEntered = true;
        //because there are splitting packages...
        for (let box of this.currentBoxes) {
          box.rack = this.input;
        }
        wsArgs = { key, type: "rack", tnum: this.tnum, rack: input };
      } else if (/^[0-9]{1,7}[a-zA-Z ]{1,2}$/.test(this.input)) {
        this.type = "assigned";
        this.midx = this.input;
        wsArgs = {
          key,
          type: "idcode",
          tnum: this.tnum,
          idcode: input
        };
      } else {
        this.type = "error";
        this.errorInfo = "It's shorter than 8 characters";
        if (this.type === "error") speak(this.errorInfo);
        return false;
      }
      if (this.type === "error") speak(this.errorInfo);

      let value;
      if (this.type === "saved" || this.type === "assigned") {
        value = this.tnum + " " + this.input;
      } else {
        value = this.input;
      }

      // manually remove history item by scanning the stale barcode
      const oldItem = this.history.findIndex(item => item.value === value);
      if (oldItem > -1) this.history.splice(oldItem, 1);

      this.history.push({ value, key: this.key });
      this.ws.send(JSON.stringify(wsArgs));
      speak(this.userid + " " + this.type);
    },
    sendMidx() {
      this.glocingWs.send(this.midx);
    },
    savePcName(pcName){
      localStorage.setItem('pcName',pcName)
      location.reload()
    },
    initSocket() {
      this.vueInsomnia().on();
      this.pcName=localStorage.getItem('pcName')
      // if user is running mozilla then use it's built-in WebSocket
      window.WebSocket = window.WebSocket || window.MozWebSocket;
      this.glocingWs = new WebSocket("wss://www.glocing.com:8013/"+this.pcName);
      this.glocingWs.onmessage = msg => {
        this.boxes = JSON.parse(msg.data);
      };
      this.ws = new WebSocket(global.baseWSURL);
      this.ws.onerror = function(error) {
        console.log(error, "ws onerror");
        // an error occurred when sending/receiving data
      };
      this.ws.onclose = e => {
        console.log(e);
        setTimeout(() => {
          this.initSocket();

          console.log("reconnected");
        }, 1000);
      };
      this.ws.onopen = function() {
        setInterval(() => {
          this.ws.send("ping");
        }, 50 * 1000);
      }.bind(this);
    },
    async syncMissedTnums() {
      let inputs = [];
      this.history.forEach(elm => {
        const values = elm.value.replace(" ", ";");
        inputs = [...inputs, ...values.split(";")];
      });
      for (const input of inputs) {
        console.log(input);
        this.input = input;
        this.rackEntered = true;
        this.scan();
        await this.$sleep(10000);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.ws.close();
    next();
  },
  watch: {
    history(history) {
      localStorage.setItem("history", JSON.stringify(history));
    }
  },
  async created() {
    this.initSocket();
  },
  mounted() {
    const historyJSON = localStorage.getItem("history");
    if (historyJSON) this.history = JSON.parse(historyJSON);
  }
};
</script>
<style scoped>
.b-card {
  font-family: Courier serif;
}
</style>
