<template>
  <div class="container-fluid">
    <b-form >
      <vue-dropzone @vdropzone-sending="sendingEvent"
                    @vdropzone-file-added="added"
                    @vdropzone-success="success"
                    @vdropzone-error="error"
                    id="myDropZone" ref="myVueDropzone" :options="dropzoneOptions">
      </vue-dropzone>
    </b-form>
    <b-card class="mt-5">
      <b-btn @click="download" class="d-block m-auto ">Download</b-btn>
    </b-card>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dropzoneOptions: {
        url: global.baseAPIURL + "/apply-excel",
        thumbnailWidth: 150,
        maxFilesize: 2,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME"
        // headers: { "My-Awesome-Header": "header value" }
      }
    };
  },
  methods: {
    async download() {
      // const { url } =
      await this.$get("/xls_smartstore");
      this.$warn("ok");
      // location.href = url;
    },
    success(fie, response) {
      if (response.error) {
        this.$warn(response.error);
      } else {
        this.$notify("Success");
      }
    },
    error() {
      this.$warn("Error");
    },
    added(file) {
      console.log(file);
      console.log(232);
      // this.$refs.myVueDropzone.processQueue();
    },
    sendingEvent(file, xhr, formData) {
      formData.append("userid", "22");
    }
  },
  mounted() {
    // const file = { size: 123, name: "Icon" };
    // const url = "file://www/box2me/coq10.jpg";
    // this.$refs.myVueDropzone.manuallyAddFile(file, url);
  }
};
</script>
<style scoped>
</style>
