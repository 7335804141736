import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Profile from "./views/Profile.vue";
import Stocking from "./views/Stocking.vue";
import StockingUnion from "./views/StockingUnion.vue";
import StockingMirror from "./views/StockingMirror.vue";
import ApplyExcel from "./views/ApplyExcel.vue";
import Smartstore from "./views/Smartstore.vue";
import AmazonIssues from "./views/AmazonIssues";
import OrderList from "./views/OrderList";
import ToPack from "./views/ToPack";
import ToPick from "./views/ToPickTableShowOneRowBtn";
import Inspection from "./views/Inspection";
import SaveDocuments from "@/views/SaveDocuments.vue";

Vue.use(Router);
export default new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Stocking,
    },
    {
      path: "/to-pick/:region",
      component: ToPick,
    },
    {
      path: "/to-pack/:region",
      component: ToPack,
    },
    {
      path: "/apply-excel",
      name: "apply-excel",
      component: ApplyExcel,
    },
    {
      path: "/stocking-mirror/:region",
      name: "stockingMirror",
      component: StockingMirror,
    },
    {
      path: "/stocking/:region/:pcno",
      name: "stocking",
      component: Stocking,
    },
    {
      path: "/stocking-union/:region/:pcno",
      name: "stocking-union",
      component: StockingUnion,
    },
    // {
    //   path: "/",
    //   name: "components",
    //   component: Stocking
    // },
    {
      path: "/amazon-issues",
      component: AmazonIssues,
    },
    {
      path: "/save-documents",
      component: SaveDocuments,
    },
    {
      path: "/order-list/:parentTable/:listType?",
      component: OrderList,
    },
    {
      path: "/smartstore/:page?",
      name: "smartstore",
      component: Smartstore,
    },
    {
      path: "/landing",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter,
      },
    },
    {
      path: "/login",
      name: "login",
      components: {
        default: Login,
      },
    },
    {
      path: "/register",
      name: "register",
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter,
      },
    },
    {
      path: "/inspection/:tnum?",
      name: "inspection",
      component: Inspection,
    },
    {
      path: "/profile",
      name: "profile",
      components: {
        header: AppHeader,
        default: Profile,
        footer: AppFooter,
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
